import { useDispatch, useSelector } from 'react-redux';
import { getPointsTransactionsListKeyStart } from './slices';
import { useEffect } from 'react';
import { AxiosError } from 'axios';

/**
 * @typedef {Object} Params
 * @property {string} id ID du noeud hiérarchique en base de données
 * @property {number} [page] Page à retourner
 * @property {number} [page_size] Nombre de résultats à retourner par page
 * @property {OwnerType[]} [owner_types] Type d'owner souhaité
 */

/**
 * @typedef {import("./types").GetPointsTransitionsResponse} GetPointsTransitionsResponse
 * @description Hook to query the points transactions of a contract (hierarchy node).
 * @returns {{data: GetPointsTransitionsResponse|null, success: boolean, error: AxiosError|null, refetch: Params => void}}
 * @param {Params} params
 */
export const usePointsTransactionsQuery = params => {
  const dispatch = useDispatch();
  const { data, success, error, loading } = useSelector(
    state => state.getPointsTransactionsKey,
  );

  const fetchFn = p => dispatch(getPointsTransactionsListKeyStart(p));

  useEffect(() => {
    fetchFn(params);
  }, []);

  const refetch = p => fetchFn(p);
  return { data, success, error, loading, refetch };
};
