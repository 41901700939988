import instance from '../instance';
import { OwnerType } from '../../../Spider/enums/ownerType';

/**
 * @param {string} id ID du noeud hiérarchique en base de données
 */
const baseUrl = id => `hierarchy-nodes/${id}/transactions`;

const pointsTransactions = {
  /**
   * @param {string} id ID du noeud hiérarchique en base de données
   * @param {number} [page] Page à retourner
   * @param {number} [page_size] Nombre de résultats à retourner par page
   * @param {OwnerType[]} [owner_types] Type d'owner souhaité
   */
  list(id, page, page_size, owner_types) {
    const params = new URLSearchParams();
    if (page) params.append('page', page);
    params.append('page_size', page_size);
    if (owner_types && Array.isArray(owner_types)) {
      owner_types.forEach(type => params.append('owner_types', type));
    }
    return instance.get(baseUrl(id), { params });
  },
};

export default pointsTransactions;
