import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, CardMedia, isWidthUp, withWidth } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { withStyles } from '@material-ui/styles';
import { Logo, Title } from './components';
import {
  BoldSpan,
  ErrorText,
  ProgressButton,
  TextField,
  BigText,
  LanguageSelect,
  I18nWrapper,
  Card,
  HiddenInput,
  PasswordField,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
} from '../../../../../../components';
import { useIntl } from 'react-intl';
import * as Resources from '../../../../../../Resources';
import * as authErrors from '../../../../../../services/Auth/errors';
import Formsy from 'formsy-react';
import KeyIcon from '../../../../../../assets/img/system/login/key.png';
import LogoBlack from '../../../../../../assets/logo_black.png';
import LogoEx from '../../../../../../assets/img/objow_logo.svg';

const styles = {
  form: {
    // backgroundColor: '#2B2E45'
  },
  ssoSwitch: {
    position: 'absolute',
    right: '50%',
    marginRight: -50,
    // bottom: '10',
    marginTop: '-31px',
  },
  ssoSwitchMobile: {
    marginTop: 10,
    marginLeft: 'calc(50% - 50px)',
    width: 200,
  },
  ssoSwitchLabel: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    fontFamily: 'Avenir',
  },
  card: {
    borderRadius: 15,
    paddingTop: 5,
    paddingBottom: 5,
  },
  inputs: {
    // overflow: "hidden",
    '& .MuiInputBase-root:before': {
      display: 'none',
    },
    '& .MuiFilledInput-root': {
      borderRadius: 15,
    },
    '& label': {
      marginTop: 5,
    },
    '& input': {
      padding: 25,
    },
    '& .MuiFilledInput-underline': {
      '&:after': {
        display: 'none',
      },
    },

    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, -20px) scale(0.75)',
      zIndex: 100,
    },

    '& label, & label.Mui-focused, & input:not(.Mui-error), & textarea:not(.Mui-error)':
      {
        textTransform: 'none',
        fontWeight: 'bold',
        fontSize: 16,
        fontFamily: 'Avenir',
        color: '#05192C',
      },
  },
  submit_button: {
    '& .MuiButton-root': {
      width: '100%',
      // height: 40,
      borderRadius: 15,
      textTransform: 'none',
      fontFamily: 'Avenir',
      fontSize: 18,
      paddingTop: 25,
      paddingBottom: 25,
      backgroundColor: '#00E094',
      '&:hover': {
        backgroundColor: '#00E094',
      },
    },
  },
  logo: {
    width: 287,
    height: 83,
    backgroundPosition: 'center center',
  },
  logoMobile: {
    width: 181,
    height: 65,
    margin: 'auto',
    paddingLeft: 10,
    marginBottom: 20,
  },
  connexionText: {
    textTransform: 'none',
    fontSize: 20,
    marginTop: 10,
    paddingTop: 5,
    paddingBottom: 5,

    color: '#05192C',
    fontFamily: 'Avenir',
    fontWeight: 'bold',
  },
  connexionTextMobile: {
    textTransform: 'none',
    fontSize: 30,
    marginTop: -2,
    paddingTop: 5,
    paddingBottom: 5,
    color: '#05192C',
    fontFamily: 'Avenir',
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: 16,
    color: '#AFAFAF',
    '&:hover': {
      color: 'rgb(15,111,222)',
      // textDecoration: "underline"
    },
  },
};

const LoginFormMobile = ({
  onSubmit,
  onSubmitSSO,
  classes,
  customError,
  resetCustomError,
  ...props
}) => {
  const context = useContext(I18nWrapper.Context);
  const [locale, setLocale] = useState(context.locale);
  const intl = useIntl();
  const [ssoDialogOpen, setSsoDialogOpen] = useState(false);
  const { loading, error } = props.auth;
  const [displayCaptcha, setDisplayCaptcha] = useState(
    error === 'connection_attempts_exceeded'
  );
  const [displaySsoLink, setDisplaySsoLink] = useState(false);
  const [captcha, setCaptcha] = useState();
  const { detect } = require('detect-browser');
  const browser = detect();
  const isMobileApp =
    browser.name === 'ios-webview' || browser.name === 'chromium-webview';
  const isDesktop = isWidthUp('sm', props.width);
  const ssoSwitchClass = isDesktop
    ? classes.ssoSwitch
    : classes.ssoSwitchMobile;
  const defaultCode = 'MGEN';

  const [sso, setSso] = useState(false);

  const onChangeCode = (value) => {
    // setSso(value.toLowerCase() === 'cegid');
  };

  const onChangeEmail = (value) => {
    setSso(value.includes('@cegid.com'));
    setDisplaySsoLink(value.includes('@objow.com'));
  };

  const onChangeLanguage = (value) => {
    setLocale(value);
  };

  const onCaptchaChange = (value) => {
    setCaptcha(value);
  };

  useEffect(() => {
    if (!displayCaptcha && error === 'connection_attempts_exceeded') {
      setDisplayCaptcha(true);
    }
  }, [error]);

  useEffect(() => {
    context.selectLanguage(locale);
    localStorage.setItem('locale', locale);
  }, [locale]);

  return (
    <div>
      <Grid
        container
        spacing={2}
        justify='flex-end'
        style={{ paddingTop: 150 }}
      >
        <Grid item xs={12} container justifyContent='center'>
          <span
            style={{
              position: 'absolute',
              top: 0,
              left: -10,
              width: 'calc(100% + 10px)',
              height: '170px',
              background: '#05192F',
              borderBottom: '4px solid #0BE095',
              zIndex: 10,
            }}
          ></span>

          <Grid item style={{ zIndex: 1000, position: 'absolute', top: 40 }}>
            <CardMedia className={classes.logo} image={LogoEx} />
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          style={{ padding: 45, paddingTop: 0, paddingBottom: 0 }}
        >
          <Grid item xs container>
            <Grid item>
              <BigText className={classes.connexionText}>
                {intl.formatMessage({ id: 'login.title' })}
              </BigText>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Formsy>
              <LanguageSelect
                name="locale"
                initial={locale}
                onChange={onChangeLanguage}
              />
            </Formsy>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <Formsy onValidSubmit={onSubmit}>
              <Grid
                container
                spacing={4}
                style={{
                  padding: 40,
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 5,
                }}
              >
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <div>
                        <Grid container spacing={4} className={classes.form}>
                          <HiddenInput name='code' value={defaultCode} />

                          <React.Fragment>
                            <Grid item xs={12}>
                              <TextField
                                lowercase={true}
                                variant='filled'
                                placeholder={intl.formatMessage({
                                  id: 'login.email_placeholder',
                                })}
                                className={classes.inputs}
                                name='email'
                                label={intl.formatMessage({
                                  id: 'login.email_label',
                                })}
                                fullWidth
                                required
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({
                                    id: 'common.form.required_error',
                                  }),
                                }}
                                disableInputLabelProps
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <PasswordField
                                lowercase={true}
                                variant='filled'
                                mediumLabel
                                placeholder='●●●●●●●'
                                className={classes.inputs}
                                type='password'
                                name='password'
                                label={intl.formatMessage({
                                  id: 'login.password_label',
                                })}
                                fullWidth
                                required
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({
                                    id: 'common.form.required_error',
                                  }),
                                }}
                                disableInputLabelProps
                              />
                            </Grid>
                          </React.Fragment>
                        </Grid>
                      </div>
                    </Grid>
                    {error === authErrors.LOGIN_ERROR ||
                      (customError && (
                        <Grid item xs={12}>
                          <ErrorText align='center'>
                            {customError ||
                              intl.formatMessage({ id: 'login.error' })}
                          </ErrorText>
                        </Grid>
                      ))}
                    {displayCaptcha && (
                      <Grid item xs={12}>
                        <ReCAPTCHA
                          sitekey='6LctniwfAAAAAE8bs1iQ-_Uuvkqya66J2qbvyg2H'
                          onChange={onCaptchaChange}
                        />
                        <HiddenInput name='captcha' value={captcha} />
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'right', marginTop: 10 }}
                  >
                    <Link
                      to='/reset-password'
                      style={{
                        textDecoration: 'none',
                        color: 'rgb(15,111,222)',
                        fontSize: 16,
                      }}
                    >
                      {intl.formatMessage({ id: 'login.reset_password' })}
                    </Link>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.submit_button}>
                  <Grid container justifyContent='center'>
                    <Grid item xs={12}>
                      <ProgressButton
                        type='submit'
                        text={intl.formatMessage({ id: 'login.submit_button' })}
                        centered
                        loading={loading}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Formsy>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={ssoDialogOpen}
        onClose={() => setSsoDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle onClose={() => setSsoDialogOpen(false)}>
          {intl.formatMessage({ id: 'login.sso_dialog_title' })}
        </DialogTitle>
        <DialogContent>
          <Formsy
            onValidSubmit={(model) => {
              const error = onSubmitSSO(model);
              if (!error) {
                setSsoDialogOpen(false);
              }
            }}
          >
            <Grid container spacing={4} className={classes.form}>
              <Grid item xs={12}>
                <TextField
                  name="code"
                  variant="filled"
                  placeholder={intl.formatMessage({
                    id: 'login.code_placeholder',
                  })}
                  className={classes.inputs}
                  label={intl.formatMessage({ id: 'login.code_label' })}
                  fullWidth
                  required
                  validationErrors={{
                    isDefaultRequiredValue: intl.formatMessage({
                      id: 'common.form.required_error',
                    }),
                  }}
                  disableInputLabelProps
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                className={classes.submit_button}
              >
                <ProgressButton
                  type="submit"
                  text={intl.formatMessage({ id: 'login.submit_button' })}
                  centered
                />
              </Grid>
            </Grid>
          </Formsy>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps)(
  withWidth()(withStyles(styles)(LoginFormMobile))
);
