import React, { useMemo } from 'react';
import {
  useParams,
  useLocation,
  useHistory,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useGetHierarchyNodeUserDetailsQuery } from '@async-calls/participants';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { TitleContainer } from '@src/Spider/components/TitleContainer';
import { useIntl } from 'react-intl';
import { ParticipantUrl } from '@src/Spider/enums/participantUrl';
import { TabContext, TabPanel } from '@material-ui/lab';

const Users = () => {
  /** @type {{contract: string, user_id: string}} */
  const { contract, user_id } = useParams();
  const { data } = useGetHierarchyNodeUserDetailsQuery({ user_id });
  const intl = useIntl();

  const history = useHistory();
  const location = useLocation();

  const tabPaths = Object.values(ParticipantUrl);
  const tabs = useMemo(
    () =>
      tabPaths.map((el, i) => ({
        label: intl.formatMessage({
          id: `spider.hierarchy_node_user_pages.tabs.${el}`,
        }),
        index: i,
        url: el,
      })),
    [intl, tabPaths],
  );

  const currentTab = location.pathname.split('/').pop();
  const currentTabIndex = tabPaths.indexOf(currentTab);
  const value = String(currentTabIndex !== -1 ? currentTabIndex : 0);

  const handleChange = (_, newValue) => {
    history.push(
      `/nodes/${contract}/users/${user_id}/${tabPaths[Number(newValue)]}`,
    );
  };

  if (!tabPaths.includes(currentTab))
    return (
      <Redirect
        to={`/nodes/${contract}/users/${user_id}/${ParticipantUrl.DETAILS}`}
      />
    );

  return (
    <Box>
      <TitleContainer>
        <Typography variant='h1' component='h1' className='underline-left'>
          {data?.firstname} {data?.lastname}
        </Typography>
      </TitleContainer>
      <Box
        style={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
          marginTop: '2rem',
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs onChange={handleChange} value={value}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.index.toString()}
                  disableFocusRipple
                />
              ))}
            </Tabs>
          </Box>
          <Switch>
            <Route
              path={`/nodes/${contract}/users/${user_id}/${ParticipantUrl.DETAILS}`}
              render={() => <TabPanel value='0'>Details Content</TabPanel>}
            />
            <Route
              path={`/nodes/${contract}/users/${user_id}/${ParticipantUrl.POINTS}`}
              render={() => <TabPanel value='1'>Points Content</TabPanel>}
            />
            <Route
              path={`/nodes/${contract}/users/${user_id}/${ParticipantUrl.ORDERS}`}
              render={() => <TabPanel value='2'>Orders Content</TabPanel>}
            />
          </Switch>
        </TabContext>
      </Box>
      <Typography>
        You are currently on the user page uuid : {user_id} from contract:
        {contract}
      </Typography>
    </Box>
  );
};

export default Users;
